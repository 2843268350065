import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import uuidGenerator from "react-uuid";
import { useOTSession } from "./provideOTSession";
import { useUserDetails } from "./provideUserDetails";
import { SocketMessage, useSocketRoot } from "./provideSocketRoot";
import UAParser from "ua-parser-js";

interface MessageListener {
  id: string;
  type: string;
  handler: (data: any) => void;
}

export const WSSessionContext = createContext<{
  isTeacher: boolean;
  readyState: boolean;
  connectedToWebSocket: boolean;
  hasConnectionError: boolean;
  isConnectedToSession: boolean;
  registerMessageListener: (listener: MessageListener) => void;
  unregisterMessageListener: (id: string) => void;
  sendMessageWithAck: (
    type: string,
    data: any,
    onAck?: null | ((data: any) => void),
    uuid?: string,
    attempts?: number,
    delay?: number
  ) => void;
} | null>(null);

export function ProvideWSSession({ children }: { children: React.ReactNode }) {
  const provider = useProvideWSSession();
  return (
    <WSSessionContext.Provider value={provider}>
      {children}
    </WSSessionContext.Provider>
  );
}

export const useWSSession = () => {
  const context = useContext(WSSessionContext);
  if (context === null) {
    throw Error("WTSession context not provided");
  }
  return context;
};

const useProvideWSSession = () => {
  const {
    setShouldConnect,
    sendMessage,
    awaitingMessages,
    getNextMessage,
    connected,
  } = useSocketRoot();
  const { id: userId, bearer, data } = useUserDetails();

  const [isTeacher, setIsTeacher] = useState(false);
  const [isConnectedToSession, setIsConnectedToSession] = useState(false);
  const [hasConnectionError, setHasConnectionError] = useState(false);
  const [messageListener, setMessageListener] = useState<MessageListener[]>([]);

  const { sessionHelper } = useOTSession();

  const awaitingAckStack = useRef<Map<String, null | ((data: any) => void)>>(
    new Map()
  );

  const handleIncomingMessage = useCallback(
    (message: SocketMessage) => {
      const m = message?.data || {};
      if (m.type === "ack") {
        const uuid = m.data.uuid;
        if (awaitingAckStack.current.has(uuid)) {
          awaitingAckStack.current?.get(uuid)?.(m.data);
          awaitingAckStack.current?.delete(uuid);
        }
      }

      messageListener.forEach((listener) => {
        if (listener.type === m.type) {
          listener.handler(m);
        }
      });
    },
    [messageListener]
  );

  const sendMessageWithAck = useCallback(
    (
      type: string,
      data: any,
      onAck: null | ((data: any) => void) = null,
      uuid: string = "",
      attempts: number = 5,
      delay: number = 1500
    ) => {
      uuid = uuid === "" ? uuidGenerator() : uuid;
      sendMessage(
        JSON.stringify({
          uuid,
          type,
          data,
        })
      );
      awaitingAckStack.current?.set(uuid, onAck);

      setTimeout(() => {
        if (awaitingAckStack.current.has(uuid)) {
          if (attempts > 0) {
            awaitingAckStack.current?.delete(uuid);
            sendMessageWithAck(type, data, onAck, uuid, attempts - 1, delay);
          }
        }
      }, delay);
    },
    [sendMessage]
  );

  const registerMessageListener = useCallback((listener: MessageListener) => {
    setMessageListener((messageListener) => [...messageListener, listener]);
  }, []);

  const unregisterMessageListener = useCallback((id: string) => {
    setMessageListener((messageListener) =>
      messageListener.filter((listener) => listener.id !== id)
    );
  }, []);

  useEffect(() => {
    if (
      sessionHelper?.session &&
      !isConnectedToSession &&
      connected &&
      (userId !== "" || data !== null) &&
      bearer !== ""
    ) {
      const parser = new UAParser();
      const browser = parser.getResult();
      sendMessageWithAck(
        "join",
        {
          sessionId: sessionHelper.session.sessionId,
          userId: userId ? userId : undefined,
          customData: data ? data : undefined,
          browser,
          bearer,
        },
        (data: any) => {
          setIsTeacher(data.isTeacher);
          setIsConnectedToSession(data.success ?? false);
          setHasConnectionError(!data.success);
        }
      );
    }
  }, [
    connected,
    isConnectedToSession,
    sendMessageWithAck,
    sessionHelper?.session,
    sendMessage,
    userId,
    data,
    bearer,
  ]);

  useEffect(() => {
    if (connected) {
      const interval = setInterval(() => {
        sendMessageWithAck("ping", {});
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [connected, sendMessageWithAck]);

  useEffect(() => {
    if (sessionHelper) {
      setShouldConnect(true);
    }
  }, [sessionHelper, setShouldConnect]);

  useEffect(() => {
    const message = getNextMessage();
    if (message) {
      handleIncomingMessage(message);
    }
  }, [handleIncomingMessage, awaitingMessages, getNextMessage]);

  return {
    connectedToWebSocket: connected,
    isTeacher,
    hasConnectionError,
    isConnectedToSession,
    readyState: isConnectedToSession,
    registerMessageListener,
    unregisterMessageListener,
    sendMessageWithAck,
  };
};

import React, { createContext, useContext } from "react";

export const BREAKPOINTS = {
  mobile: 480,
  tablet: 1000,
  desktop: 1200,
};

export const ScreenSizeContext = createContext<{
  isMobileOrTablet: boolean;
  isMobile: boolean;
  isTablet: boolean;
  windowSize: { width: number; height: number };
} | null>(null);

export function ProvideScreenSize({ children }: { children: React.ReactNode }) {
  const provider = useProvideScreenSize();
  return (
    <ScreenSizeContext.Provider value={provider}>
      {children}
    </ScreenSizeContext.Provider>
  );
}

export const useScreenSize = () => {
  const context = useContext(ScreenSizeContext);
  if (context === null) {
    throw Error("ScreenSize context not provided");
  }
  return context;
};

const useProvideScreenSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);
  const isMobile = window.innerWidth <= BREAKPOINTS.mobile;
  const isTablet = window.innerWidth <= BREAKPOINTS.tablet;

  return {
    isMobileOrTablet: isMobile || isTablet,
    isMobile,
    isTablet,
    windowSize,
  };
};

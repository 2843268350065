import {
  Flex,
  useColorModeValue,
  Text,
  Grid,
  Button,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import Config from "../../Config";

const QUESTIONS = [
  [
    "Géometrie",
    "Quelle est l’aire d’une sphère de rayon R = 5 cm arrondie au centième ?",
    "314",
  ],
  ["Histoire", "Qui était le père de Vercingétorix ?", "Celtillos"],
  [
    "Histoire",
    "Où débarquent les troupes anglo-saxonnes pour libérer la France ?",
    "Normandie",
  ],
  [
    "Biologie",
    "Qu'est-ce que la photosynthèse ?",
    "La photosynthèse consiste à transformer l'énergie lumineuse en énergie chimique, c'est à dire que l'énergie lumineuse est mise en réserve dans les liaisons chimiques d'un composé bien connu : le glucose. Ce phénomène s'accompagne de l'absorption de dioxyde de carbone (CO2) et de production d'oxygène (O2).",
  ],
];

export default function Companion() {
  const bgColor = useColorModeValue("white", "gray.700");

  const [index, setIndex] = useState(0);
  const [chat, setChat] = useState("");
  const [answers, setAnswers] = useState<string[]>([]);
  const [partialAnswer, setPartialAnswer] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setChat("");
    setAnswers([]);
    setIndex((prev) => (prev + 1) % QUESTIONS.length);
  };

  const fetchResponse = async (retry = 0) => {
    setLoading(true);
    setAnswers((prev) => [...prev, chat]);
    setChat("");
    try {
      const result = await fetch(`${Config.EXPRESS.host}public/companion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: QUESTIONS[index][1],
          discussion: [...answers, chat],
          answer: QUESTIONS[index][2],
        }),
      });

      const reader = result?.body?.getReader();
      let chunks = "";
      let stopLoop = false;
      const decoder = new TextDecoder();

      while (reader && !stopLoop) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value || new Uint8Array(), {
          stream: !done,
        });
        const chunkListWithCommas = "[" + chunk.split("}{").join("},{") + "]";
        const chunkList = JSON.parse(chunkListWithCommas) as {
          chunk: string;
        }[];

        for (let index = 0; index < chunkList.length; index++) {
          const parsed = chunkList[index];
          if (parsed.chunk) {
            chunks = chunks + parsed.chunk;
            setPartialAnswer(chunks);
          }
        }
      }

      setAnswers((prev) => [...prev, chunks]);
      setPartialAnswer(undefined);
      setLoading(false);
    } catch (e) {
      if (retry < 10) {
        fetchResponse(retry + 1);
      }
    }
  };

  return (
    <Flex
      background="rgb(32, 33, 35)"
      backgroundSize="cover"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      padding={3}
    >
      <Flex
        flexDirection="column"
        borderRadius={6}
        bg={bgColor}
        gap={3}
        p={3}
        overflow="auto"
        width="80vw"
        maxWidth="80vw"
      >
        <Text fontSize="xl" fontWeight="semibold" textAlign="center">
          {QUESTIONS[index][0]}
        </Text>
        <Text fontSize="l" fontWeight="semibold" textAlign="center" mb="30px">
          {QUESTIONS[index][1]}
        </Text>
        {answers.map((answer, i) => (
          <Text
            fontSize="l"
            mb="30px"
            key={`answer_${i}`}
            color={
              answer.startsWith("{TRUE}")
                ? "green.400"
                : answer.startsWith("{FALSE}")
                ? "red.400"
                : undefined
            }
          >
            {answer.startsWith("{TRUE}")
              ? answer.slice(6)
              : answer.startsWith("{FALSE}")
              ? answer.slice(7)
              : answer}
          </Text>
        ))}
        {partialAnswer &&
          (partialAnswer.startsWith("{TRUE}") ||
            partialAnswer.startsWith("{FALSE}")) && (
            <Text
              fontSize="l"
              mb="30px"
              color={
                partialAnswer.startsWith("{TRUE}")
                  ? "green.400"
                  : partialAnswer.startsWith("{FALSE}")
                  ? "red.400"
                  : undefined
              }
            >
              {partialAnswer.startsWith("{TRUE}")
                ? partialAnswer.slice(6)
                : partialAnswer.startsWith("{FALSE}")
                ? partialAnswer.slice(7)
                : partialAnswer}
            </Text>
          )}
        <Textarea
          value={chat}
          onChange={(e) => setChat(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              fetchResponse(0);
              e.preventDefault();
              e.stopPropagation();
              return false;
            }
          }}
        />
        {loading && <Spinner />}
        {!loading && (
          <Grid gridTemplateColumns="1fr 1fr" gap={3}>
            <Button onClick={handleNext}>Autre</Button>
            <Button colorScheme="green" onClick={() => fetchResponse(0)}>
              Valider
            </Button>
          </Grid>
        )}
      </Flex>
    </Flex>
  );
}

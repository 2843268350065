import React, { createContext, useContext, useState } from "react";

export const UserDetailsContext = createContext<{
  studentId: string;
  id: string;
  setId: (id: string) => void;
  bearer: string;
  setBearer: (id: string) => void;
  data: any;
  setData: (data: any) => void;
} | null>(null);

export function ProvideUserDetails({
  children,
}: {
  children: React.ReactNode;
}) {
  const provider = useProvideUserDetails();
  return (
    <UserDetailsContext.Provider value={provider}>
      {children}
    </UserDetailsContext.Provider>
  );
}

export const useUserDetails = () => {
  const context = useContext(UserDetailsContext);
  if (context === null) {
    throw Error("UserDetails context not provided");
  }
  return context;
};

const useProvideUserDetails = () => {
  const [id, setId] = useState("");
  const [bearer, setBearer] = useState("");
  const [data, setData] = useState<any | null>(null);

  return {
    id,
    setId,
    bearer,
    setBearer,
    data,
    setData,
    studentId: data?.id ?? "",
  };
};

import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import PageLoader from "./components/PageLoader";
import { ProvideScreenSize } from "./hooks/provideScreenSize";
import {
  ProvideAuth,
  RedirectIfLoggedIn,
  RedirectUnlessLoggedIn,
} from "./hooks/provideAuth";
import { ProvideOTSession } from "./hooks/provideOTSession";
import { ProvideWSSession } from "./hooks/provideWSSession";
import { ProvideUserDetails } from "./hooks/provideUserDetails";
import Entrance from "./pages/room/Entrance";
import { ProvideNotifications } from "./hooks/ProvideNotifications";
import { ProvideSessionFiles } from "./hooks/ProvideSessionFiles";
import Poll from "./pages/poll/Poll";
import End from "./pages/end/End";
import { ProvideWhiteboard } from "./hooks/provideWhiteboard";
import { ProvideSocketRoot } from "./hooks/provideSocketRoot";
import Companion from "./pages/companion/Companion";
import { ProvideUserList } from "./hooks/provideUserList";
import * as Sentry from "@sentry/react";
import { ProvideChat } from "./hooks/provideChat";

Sentry.init({
  dsn: "https://366f6fa347257725591f24c825400314@o115775.ingest.us.sentry.io/4507130098679808",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/theta.wakirisapi\.com\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const Profil = React.lazy(() => import("./pages/profil/Profil"));
const Room = React.lazy(() => import("./pages/room/Room"));
const Login = React.lazy(() => import("./pages/login/Login"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProvideAuth>
              <RedirectIfLoggedIn to="/dashboard">
                <Login />
              </RedirectIfLoggedIn>
            </ProvideAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/dashboard"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProvideAuth>
              <RedirectUnlessLoggedIn to="/">
                <Dashboard />
              </RedirectUnlessLoggedIn>
            </ProvideAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/profil"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProvideAuth>
              <RedirectUnlessLoggedIn to="/">
                <Profil />
              </RedirectUnlessLoggedIn>
            </ProvideAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/room"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProvideSocketRoot>
              <ProvideAuth>
                <ProvideUserDetails>
                  <ProvideOTSession>
                    <ProvideWSSession>
                      <ProvideSessionFiles>
                        <ProvideChat>
                          <ProvideWhiteboard>
                            <ProvideUserList>
                              <Room />
                            </ProvideUserList>
                          </ProvideWhiteboard>
                        </ProvideChat>
                      </ProvideSessionFiles>
                    </ProvideWSSession>
                  </ProvideOTSession>
                </ProvideUserDetails>
              </ProvideAuth>
            </ProvideSocketRoot>
          </React.Suspense>
        }
      />
      <Route
        path="/entrance"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProvideUserDetails>
              <Entrance />
            </ProvideUserDetails>
          </React.Suspense>
        }
      />
      <Route
        path="/poll"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <Poll />
          </React.Suspense>
        }
      />
      <Route
        path="/end"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <End />
          </React.Suspense>
        }
      />
      <Route
        path="/companion"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <Companion />
          </React.Suspense>
        }
      />
    </>
  )
);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <>
    <ColorModeScript />
    <ProvideNotifications>
      <ProvideScreenSize>
        <ChakraProvider theme={theme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </ProvideScreenSize>
    </ProvideNotifications>
  </>
);

serviceWorker.unregister();

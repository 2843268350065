import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  createSession as createSessionApi,
  SessionHelper,
} from "opentok-react";
import Config from "../Config";
import { Stream } from "opentok-react/types/opentok";
import { getToken } from "../services/auth";
import { useUserDetails } from "./provideUserDetails";

export const OTSessionContext = createContext<{
  createSession: (
    sessionId: string,
    token: string,
    onStreamsUpdate: (streams: Stream[]) => void
  ) => Promise<SessionHelper | null>;
  sessionHelper: SessionHelper | null;
  connected: Boolean;
} | null>(null);

export function ProvideOTSession({ children }: { children: React.ReactNode }) {
  const provider = useProvideOTSession();
  return (
    <OTSessionContext.Provider value={provider}>
      {children}
    </OTSessionContext.Provider>
  );
}

export const useOTSession = () => {
  const context = useContext(OTSessionContext);
  if (context === null) {
    throw Error("OTSession context not provided");
  }
  return context;
};

const useProvideOTSession = () => {
  const sessionHelper = useRef<SessionHelper | null>(null);

  const { studentId } = useUserDetails();

  const [connected, setConnected] = useState(false);

  const createSession = useCallback(
    async (
      sessionId: string,
      token: string,
      onStreamsUpdate?: (streams: Stream[]) => void
    ) => {
      if (studentId === null) {
        return null;
      }

      if (sessionHelper.current !== null) {
        return sessionHelper.current;
      }

      if (token === "needed") {
        const tokenResult = await getToken({ sessionId, studentId });
        if (tokenResult.error) {
          return null;
        }
        token = tokenResult.data;
      }

      const newSessionHelper = createSessionApi({
        apiKey: Config.TOKBOX.apiKey,
        sessionId,
        token,
        onStreamsUpdated: (s) => {
          onStreamsUpdate && onStreamsUpdate(s);
        },
      });
      sessionHelper.current = newSessionHelper;
      setConnected(true);
      return newSessionHelper;
    },
    [studentId]
  );

  return {
    sessionHelper: sessionHelper.current,
    createSession,
    connected,
  };
};

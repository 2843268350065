import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Config from "../Config";
import uuidGenerator from "react-uuid";

export type SocketMessage = { data: any };

export const SocketRootContext = createContext<{
  shouldConnect: boolean;
  setShouldConnect: (shouldConnect: boolean) => void;
  connected: boolean;
  sendMessage: (data: string) => void;
  awaitingMessages: string;
  getNextMessage: () => SocketMessage | null;
} | null>(null);

export function ProvideSocketRoot({ children }: { children: React.ReactNode }) {
  const provider = useProvideSocketRoot();
  return (
    <SocketRootContext.Provider value={provider}>
      {children}
    </SocketRootContext.Provider>
  );
}

export const useSocketRoot = () => {
  const context = useContext(SocketRootContext);
  if (context === null) {
    throw Error("SocketRoot context not provided");
  }
  return context;
};

const useProvideSocketRoot = () => {
  const socket = useRef<WebSocket | null>(null);
  const messagesStack = useRef<any[]>([]);

  const [awaitingMessages, setAwaitingMessages] = useState("");
  const [shouldConnect, setShouldConnect] = useState(false);
  const [connected, setConnected] = useState(false);

  const sendMessage = useCallback((data: any) => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      console.log("[WS] sending", data);
      socket.current.send(data);
    }
  }, []);

  const getNextMessage = useCallback(() => {
    if (messagesStack.current.length === 0) {
      return null;
    }
    setAwaitingMessages(uuidGenerator());
    const msg = messagesStack.current.shift();
    return { data: JSON.parse(msg) };
  }, [messagesStack]);

  useEffect(() => {
    if (socket.current !== null) {
      return;
    }

    if (!shouldConnect) {
      socket.current = null;
      return;
    }

    console.log("[WS] connecting");
    socket.current = new WebSocket(Config.WEBSOCKET.host);
    socket.current.addEventListener("open", () => {
      console.log("[WS] opened");
      setConnected(true);
    });
    socket.current.addEventListener("close", () => {
      console.log("[WS] closed");
      setConnected(false);
      setShouldConnect(false);
      socket.current = null;
      setTimeout(() => {
        setShouldConnect(true);
      }, 100);
    });
    socket.current.addEventListener("message", (event) => {
      console.log("[WS] message", event.data);
      messagesStack.current.push(event.data);
      setAwaitingMessages(uuidGenerator());
    });
  }, [shouldConnect]);

  useEffect(() => {
    console.log("[WS] awaitingMessages", awaitingMessages);
  }, [awaitingMessages]);

  return {
    shouldConnect,
    setShouldConnect,
    connected,
    sendMessage,
    getNextMessage,
    awaitingMessages,
  };
};

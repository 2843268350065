import {
  Flex,
  useColorModeValue,
  Text,
  Input,
  Button,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useState } from "react";
import { savePoll } from "../../services/auth";
import { useSearchParams } from "react-router-dom";

const QUESTIONS = [
  "Quelle note donnes-tu à la réponse apportée ?",
  "Quelle note donnes-tu au tuteur qui t'a répondu ?",
  "Quelle note donnes-tu au service de profs en ligne ? (5: je reviendrai, 1: je ne reviendrai pas)",
];

export default function Poll() {
  const bgColor = useColorModeValue("white", "gray.700");
  const [searchParams] = useSearchParams();

  const teacherId = searchParams.get("teacherId");
  const bearer = searchParams.get("bearer");
  const data = JSON.parse(searchParams.get("userData") ?? "{}");

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [answers, setAnswers] = useState<number[]>([-1, -1, -1]);

  const handleAnswer = (index: number, value: number) => {
    setAnswers((answers) => {
      const newAnswers = [...answers];
      newAnswers[index] = value;
      return newAnswers;
    });
  };

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    await savePoll({
      teacherId,
      bearer,
      answers,
      comment,
      studentId: data.id ?? null,
    });
    document.location.href = data.redirectionUrlOnEnd ?? "/end";
  };

  return (
    <Flex
      background="rgb(32, 33, 35)"
      backgroundSize="cover"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      padding={3}
    >
      <Flex
        flexDirection="column"
        borderRadius={6}
        bg={bgColor}
        gap={3}
        p={3}
        overflow="auto"
      >
        <Text fontSize="xl" fontWeight="semibold" textAlign="center">
          Questionnaire de satisfaction
        </Text>
        <Text fontSize="l" fontWeight="semibold" textAlign="center" mb="30px">
          Votre avis nous intéresse !
        </Text>
        <Flex flexDirection="column" gap={8}>
          {QUESTIONS.map((question, index) => (
            <Flex flexDirection="column" gap={3} key={index}>
              <Text fontSize="12px" fontWeight="semibold">
                {question}
              </Text>
              <Flex gap={8}>
                {[1, 2, 3, 4, 5].map((value) => (
                  <RadioGroup value={`${answers[index]}`} key={value}>
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      gap={1}
                      onClick={() => handleAnswer(index, value)}
                      cursor="pointer"
                    >
                      <img
                        src={`/${value}.png`}
                        alt={`${value}`}
                        style={{ width: "32px", height: "32px" }}
                      />
                      <Text fontWeight="bold">{value}</Text>
                      <Radio value={`${value}`} />
                    </Flex>
                  </RadioGroup>
                ))}
              </Flex>
            </Flex>
          ))}
          <Flex flexDirection="column" gap={3}>
            <Text fontSize="12px" fontWeight="semibold">
              Laisse nous un commentaire si tu le souhaites
            </Text>
            <Input
              value={comment}
              onChange={(v) => setComment(v.target.value)}
            />
          </Flex>
          {answers.findIndex((v) => v === -1) === -1 && (
            <Button
              onClick={handleSubmit}
              isLoading={loading}
              colorScheme="green"
            >
              Envoyer
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

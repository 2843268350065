import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";

export default function Background({
  children,
}: {
  children?: React.ReactNode;
}) {
  const bgColor = useColorModeValue("gray.200", "gray.800");

  return (
    <Box fontSize="xl" bg={bgColor} minHeight="100vh">
      {children}
    </Box>
  );
}

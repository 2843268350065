import React, { createContext, useContext, useEffect, useState } from "react";
import { useWSSession } from "./provideWSSession";

interface SessionFile {
  name: string;
  from: string;
}

export const SessionFilesContext = createContext<{
  files: SessionFile[];
  addFile: (file: SessionFile) => void;
  removeFile: (file: SessionFile) => void;
} | null>(null);

export function ProvideSessionFiles({
  children,
}: {
  children: React.ReactNode;
}) {
  const provider = useProvideSessionFiles();
  return (
    <SessionFilesContext.Provider value={provider}>
      {children}
    </SessionFilesContext.Provider>
  );
}

export const useSessionFiles = () => {
  const context = useContext(SessionFilesContext);
  if (context === null) {
    throw Error("SessionFiles context not provided");
  }
  return context;
};

const useProvideSessionFiles = () => {
  const { registerMessageListener, unregisterMessageListener } = useWSSession();

  const [files, setFiles] = useState<SessionFile[]>([]);

  const addFile = (file: SessionFile) => {
    setFiles((files) => {
      if (!files.some((f) => f.name === file.name)) {
        return [...files, file];
      }
      return files;
    });
  };

  const removeFile = (file: SessionFile) => {
    setFiles((files) => files.filter((f) => f.name !== file.name));
  };

  useEffect(() => {
    registerMessageListener({
      id: "new-file-listener",
      type: "new-file",
      handler: (message) => {
        setFiles((files) => {
          const newFiles = [...files, message.data];
          return Array.from(new Map(newFiles.map((v) => [v.name, v])).values());
        });
      },
    });

    registerMessageListener({
      id: "all-files-listener",
      type: "all-files",
      handler: (message) => {
        const files = message.data as SessionFile[];
        setFiles(Array.from(new Map(files.map((v) => [v.name, v])).values()));
      },
    });

    return () => {
      unregisterMessageListener("new-file-listener");
      unregisterMessageListener("all-files-listener");
    };
  }, [registerMessageListener, unregisterMessageListener]);

  return {
    files,
    addFile,
    removeFile,
  };
};

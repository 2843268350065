import Config from "../Config";

export const createClient = (serviceName: string) => {
  return {
    new: (abortController?: AbortController) =>
      new ApiClient(serviceName, abortController),
  };
};

class ApiClient {
  routePrefix: string;
  abortController?: AbortController;
  method = "POST";
  endpoint = "/";
  token = "";
  bodyData: object | null = null;
  formDataValue: FormData | null = null;
  headersObject: Record<string, string> = {};

  constructor(routePrefix: string, abortController?: AbortController) {
    this.routePrefix = routePrefix;
    this.abortController = abortController;
  }

  fetch = async <T = {}>(
    contentType: string = "application/json"
  ): Promise<T> => {
    try {
      const response = await fetch(
        `${Config.EXPRESS.host}${this.routePrefix}${this.endpoint}`,
        {
          method: this.method,
          signal: this.abortController
            ? this.abortController.signal
            : undefined,
          headers: {
            ...(contentType !== "" ? { "Content-Type": contentType } : {}),
            ...(this.token !== "" ? { token: this.token } : {}),
            ...this.headersObject,
          },
          ...(this.bodyData !== null
            ? { body: JSON.stringify(this.bodyData) }
            : this.formDataValue !== null
            ? { body: this.formDataValue }
            : {}),
        }
      );
      return (await response.json()) as T;
    } catch (e) {
      const error = e as { message?: string } | undefined;
      if (!this.abortController || !this.abortController.signal.aborted) {
        console.log({
          error: true,
          message: error?.message || "",
          data: [],
          result: [],
        });
      }
      return { error: true, message: error?.message ?? "" } as T;
    }
  };

  headers = (headers: Record<string, string>) => {
    this.headersObject = headers;
    return this;
  };

  body = <T extends object>(data: T) => {
    this.bodyData = data;
    return this;
  };

  formData = (data: FormData) => {
    this.formDataValue = data;
    return this;
  };

  auth = (token: string) => {
    this.token = token;
    return this;
  };

  post = (endpoint: string) => {
    this.method = "POST";
    this.endpoint = endpoint;
    return this;
  };

  get = (endpoint: string) => {
    this.method = "GET";
    this.endpoint = endpoint;
    return this;
  };

  patch = (endpoint: string) => {
    this.method = "PATCH";
    this.endpoint = endpoint;
    return this;
  };

  put = (endpoint: string) => {
    this.method = "PUT";
    this.endpoint = endpoint;
    return this;
  };

  delete = (endpoint: string) => {
    this.method = "DELETE";
    this.endpoint = endpoint;
    return this;
  };
}

import React, { createContext, useContext, useState } from "react";

interface NotificationItem {
  id: number;
  title: string;
  message: string;
}

export const NotificationsContext = createContext<{
  notifications: NotificationItem[];
  addNotification: (notification: Omit<NotificationItem, "id">) => void;
} | null>(null);

export function ProvideNotifications({
  children,
}: {
  children: React.ReactNode;
}) {
  const provider = useProvideNotifications();
  return (
    <NotificationsContext.Provider value={provider}>
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === null) {
    throw Error("Notifications context not provided");
  }
  return context;
};

const useProvideNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const addNotification = (notification: Omit<NotificationItem, "id">) => {
    setNotifications((notifications) => [
      ...notifications,
      { ...notification, id: notifications.length + 1 },
    ]);
  };

  return {
    notifications,
    addNotification,
  };
};

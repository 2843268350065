import React from "react";
import Background from "./Background";
import Sidebar from "./Sidebar/Sidebar";
import MainPanel from "./MainPanel";
import { Spinner } from "@chakra-ui/react";

export default function PageLoader() {
  return (
    <Background>
      <Sidebar />
      <MainPanel>
        <Spinner />
      </MainPanel>
    </Background>
  );
}

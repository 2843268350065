import { Box, Spinner } from "@chakra-ui/react";

export default function FullPageLoader() {
  return (
    <Box
      position="fixed"
      display="flex"
      alignItems="center"
      justifyContent="center"
      top={0}
      left={0}
      bottom={0}
      right={0}
      zIndex={1000}
    >
      <Spinner size="xl" />
    </Box>
  );
}

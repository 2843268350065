import { useCallback, useState } from "react";

export default function useLocalStorage<T = any>(
  key: string,
  initialValue: T,
  transformer: (data: T) => T = (p) => p
) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (transformer(JSON.parse(item)) as any) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        setStoredValue((previous: any) => {
          const valueToStore =
            value instanceof Function ? value(previous) : value;
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
          return valueToStore;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [key]
  );

  return [storedValue, setValue];
}

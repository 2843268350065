import { Flex, useColorModeValue, Text } from "@chakra-ui/react";

export default function End() {
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Flex
      background="rgb(32, 33, 35)"
      backgroundSize="cover"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      padding={3}
    >
      <Flex
        flexDirection="column"
        borderRadius={6}
        bg={bgColor}
        gap={3}
        p={3}
        overflow="auto"
      >
        <Text fontSize="xl" fontWeight="semibold" textAlign="center">
          Merci !
        </Text>
        <Text fontSize="l" fontWeight="semibold" textAlign="center" mb="30px">
          Votre session est désormais terminée.
        </Text>
      </Flex>
    </Flex>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { useWSSession } from "./provideWSSession";

export interface UserItem {
  id: string;
  name: string;
  data: any;
}

export const UserListContext = createContext<{
  users: UserItem[];
} | null>(null);

export function ProvideUserList({ children }: { children: React.ReactNode }) {
  const provider = useProvideUserList();
  return (
    <UserListContext.Provider value={provider}>
      {children}
    </UserListContext.Provider>
  );
}

export const useUserList = () => {
  const context = useContext(UserListContext);
  if (context === null) {
    throw Error("UserList context not provided");
  }
  return context;
};

const useProvideUserList = () => {
  const { registerMessageListener, unregisterMessageListener } = useWSSession();

  const [users, setUsers] = useState<UserItem[]>([]);

  useEffect(() => {
    registerMessageListener({
      id: "new-user-listener",
      type: "new-user",
      handler: (message) => {
        setUsers((users) => {
          const newUsers = [...users, message.data];
          return Array.from(new Map(newUsers.map((v) => [v.name, v])).values());
        });
      },
    });

    registerMessageListener({
      id: "users-listener-all",
      type: "all-users",
      handler: (messages) => {
        const files = messages.data as UserItem[];
        setUsers(Array.from(new Map(files.map((v) => [v.name, v])).values()));
      },
    });

    return () => {
      unregisterMessageListener("new-user-listener");
      unregisterMessageListener("users-listener-all");
    };
  }, [registerMessageListener, unregisterMessageListener, setUsers]);

  return {
    users,
  };
};

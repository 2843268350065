import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface SidebarButtonProps {
  icon: React.ReactElement;
  label: string;
  path: string;
  selected?: boolean;
}

export default function SidebarButton({
  icon,
  label,
  path,
  selected,
}: SidebarButtonProps) {
  const navigate = useNavigate();

  const hoverBgColor = useColorModeValue("white", "gray.700");
  const iconColor = useColorModeValue("teal.300", "white");

  return (
    <Flex
      align="center"
      p={3}
      borderRadius={12}
      bg={selected ? hoverBgColor : undefined}
      w="100%"
      cursor="pointer"
      gap={3}
      role="group"
      _hover={{
        background: hoverBgColor,
      }}
      transition="0.2s linear"
      onClick={() => navigate(path)}
    >
      <Box
        bg={selected ? "teal.300" : undefined}
        lineHeight="18px"
        p={1.5}
        borderRadius={10}
        color={selected ? "white" : iconColor}
        _groupHover={{
          background: "teal.300",
          color: "white",
        }}
        transition="0.2s linear"
      >
        {React.cloneElement(icon, {
          size: "18px",
          _groupHover: { color: "white" },
        })}
      </Box>
      <Text fontSize="md" fontWeight="semibold">
        {label}
      </Text>
    </Flex>
  );
}

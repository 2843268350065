import { Box, VStack, Image, useColorModeValue } from "@chakra-ui/react";
import { AiFillHome, AiOutlineUser } from "react-icons/ai";
import SidebarButton from "./SidebarButton";

export default function Sidebar({ force }: { force?: boolean }) {
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Box
      display={[force ? "block" : "none", force ? "block" : "none", "block"]}
      position="fixed"
      zIndex={100}
      top={force ? 0 : undefined}
      left={force ? 0 : undefined}
      bgColor={force ? bgColor : undefined}
      boxShadow={force ? "5px 0px 30px 0px rgba(0,0,0,0.6)" : undefined}
    >
      <Box
        w="260px"
        maxW="260px"
        h={force ? "100vh" : "calc(100vh - 32px)"}
        p="0 20px"
        m={force ? undefined : "12px 0 12px 12px"}
      >
        <Box textAlign="center" p={8}>
          <Image height="32px" src="/logo.svg" alt="Wakiris" display="inline" />
        </Box>
        <VStack spacing={3}>
          <SidebarButton
            icon={<AiFillHome />}
            label="Accueil"
            path="/dashboard"
            selected={document.location.pathname.includes("/dashboard")}
          />
          <SidebarButton
            icon={<AiOutlineUser />}
            label="Profil"
            path="/profil"
            selected={document.location.pathname.includes("/profil")}
          />
        </VStack>
      </Box>
    </Box>
  );
}

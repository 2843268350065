import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useLocalStorage from "./useLocalStorage";
import {
  LoginResult,
  login as loginEndpoint,
  check as checkEndpoint,
} from "../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import FullPageLoader from "../components/FullPageLoader";
import { User } from "../types";

export const AuthContext = createContext<{
  user: User | null;
  accessToken: string;
  isLoggedIn: boolean;
  isProcessing: boolean;
  login: (username: string, password: string) => Promise<LoginResult>;
  logout: () => void;
} | null>(null);

export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const provider = useProvideAuth();
  return (
    <AuthContext.Provider value={provider}>
      {provider.isProcessing && <FullPageLoader />}
      {!provider.isProcessing && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw Error("Auth context not provided");
  }
  return context;
};

const useProvideAuth = () => {
  const location = useLocation();

  const previousAccessToken = useRef("");
  const [accessToken, setAccessToken] = useLocalStorage("wapi-accessToken", "");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const login = useCallback(
    async (login: string, password: string) => {
      const result = await loginEndpoint({ login, password });
      if (!result.error) {
        setAccessToken(result.data?.accessToken || "");
      }
      return result;
    },
    [setAccessToken]
  );

  const logout = useCallback(() => {
    setAccessToken("");
  }, [setAccessToken]);

  const checkToken = useCallback(() => {
    checkEndpoint({ token: accessToken }).then((result) => {
      if (result.error) {
        setAccessToken("");
      } else {
        setUser(result.data);
      }
      setIsProcessing(false);
    });
  }, [accessToken, setAccessToken]);

  useEffect(() => {
    setIsLoggedIn(accessToken !== "");
    if (accessToken !== previousAccessToken.current && accessToken !== "") {
      checkToken();
    } else {
      setIsProcessing(false);
    }
    previousAccessToken.current = accessToken;
  }, [accessToken, setAccessToken, checkToken]);

  useEffect(() => {
    if (
      isLoggedIn &&
      accessToken !== previousAccessToken.current &&
      accessToken !== ""
    ) {
      checkToken();
    }
  }, [location.pathname, isLoggedIn, checkToken, accessToken]);

  return { user, accessToken, isLoggedIn, isProcessing, login, logout };
};

export const RedirectIfLoggedIn = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate(to);
    }
  }, [auth.isLoggedIn, navigate, to]);

  return <>{children}</>;
};

export const RedirectUnlessLoggedIn = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isLoggedIn && !auth.isProcessing) {
      navigate(to);
    }
  }, [auth.isProcessing, auth.isLoggedIn, navigate, to]);

  return <>{children}</>;
};

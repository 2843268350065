import { User } from "../types";
import { createClient } from "./client";

const publicClient = createClient("public");
const authClient = createClient("auth");

export type LoginResult = {
  error: boolean;
  data: { accessToken: string };
  message: string;
};

export const login = async ({
  login,
  password,
}: {
  login: string;
  password: string;
}) => {
  try {
    const result = await publicClient
      .new()
      .post("/login")
      .body({ login, password })
      .fetch<LoginResult>();
    return result;
  } catch (e) {
    return {
      error: true,
      data: { accessToken: "" },
      message: "Une erreur est survenue",
    };
  }
};

export type GetTokenResult = {
  error: boolean;
  data: string;
};

export const getToken = async ({
  studentId,
  sessionId,
}: {
  studentId: string;
  sessionId: string;
}) => {
  try {
    const result = await publicClient
      .new()
      .get(`/rooms/token/${studentId}?sessionId=${sessionId}`)
      .fetch<GetTokenResult>();
    return result;
  } catch (e) {
    return {
      error: true,
      data: "Une erreur est survenue",
    };
  }
};

export type RegisterToRoomResult = {
  error: boolean;
  data: string;
};

export const registerToRoom = async ({
  studentId,
  bearer,
  id,
}: {
  studentId: string;
  bearer: string;
  id: number;
}) => {
  try {
    const result = await publicClient
      .new()
      .post(`/rooms/${id}/reach/${studentId}/${bearer}`)
      .fetch<RegisterToRoomResult>();
    return result;
  } catch (e) {
    return {
      error: true,
      data: "Une erreur est survenue",
    };
  }
};

export type GetRoomStatusResult = {
  error: boolean;
  data: string;
};

export const getRoomStatus = async ({
  studentId,
  bearer,
  id,
}: {
  studentId: string;
  bearer: string;
  id: number;
}) => {
  try {
    const result = await publicClient
      .new()
      .post(`/rooms/${id}/reach/${studentId}/${bearer}/status`)
      .fetch<GetRoomStatusResult>("");
    return result;
  } catch (e) {
    return {
      error: true,
      data: "Une erreur est survenue",
    };
  }
};

export type SavePreRoomAnswersResult = {
  error: boolean;
  data: { id: string; token: string; bearer: string };
};

export const savePreRoomAnswers = async ({
  studentId,
  bearer,
  id,
  answers,
}: {
  studentId: string;
  bearer: string;
  id: number;
  answers: Record<string, string>;
}) => {
  try {
    const result = await publicClient
      .new()
      .post(`/rooms/${id}/questions/${studentId}/${bearer}/save`)
      .body({ answers })
      .fetch<SavePreRoomAnswersResult>();
    return result;
  } catch (e) {
    return {
      error: true,
      data: { id: "", token: "", bearer: "" },
    };
  }
};

export type UploadToRoomResult = {
  error: boolean;
  data: string;
};

export const uploadToRoomFromWithin = async ({
  formData,
  sessionId,
  userId,
}: {
  formData: FormData;
  sessionId: string;
  userId: string;
}) => {
  try {
    const result = await publicClient
      .new()
      .post(
        `/rooms/within/upload/${userId}?sessionId=${encodeURIComponent(
          sessionId
        )}`
      )
      .formData(formData)
      .fetch<UploadToRoomResult>("");
    return result;
  } catch (e) {
    return {
      error: true,
      data: "Une erreur est survenue",
    };
  }
};

export const uploadToRoom = async ({
  formData,
  studentId,
  bearer,
  id,
}: {
  formData: FormData;
  studentId: string;
  bearer: string;
  id: number;
}) => {
  try {
    const result = await publicClient
      .new()
      .post(`/rooms/${id}/upload/${studentId}/${bearer}`)
      .formData(formData)
      .fetch<UploadToRoomResult>("");
    return result;
  } catch (e) {
    return {
      error: true,
      data: "Une erreur est survenue",
    };
  }
};

export type CheckResult = {
  error: boolean;
  data: User | null;
};

export const check = async ({ token }: { token: string }) => {
  try {
    const result = await authClient
      .new()
      .auth(token)
      .get("/check")
      .fetch<CheckResult>();
    return result;
  } catch (e) {
    return {
      error: true,
      data: null,
    };
  }
};

export type AvatarResult = {
  error: boolean;
  data: string;
};

export const uploadAvatar = async ({
  formData,
  token,
}: {
  formData: FormData;
  token: string;
}) => {
  try {
    const result = await authClient
      .new()
      .auth(token)
      .post("/avatar")
      .formData(formData)
      .fetch<AvatarResult>("");
    return result;
  } catch (e) {
    return {
      error: true,
      data: "favicon.png",
    };
  }
};

export type BooleanResult = {
  error: boolean;
  message?: string;
};

export const changePassword = async ({
  oldPassword,
  newPassword,
  confirmedPassword,
  token,
}: {
  oldPassword: string;
  newPassword: string;
  confirmedPassword: string;
  token: string;
}) => {
  try {
    const result = await authClient
      .new()
      .auth(token)
      .post("/password")
      .body({ oldPassword, newPassword, confirmedPassword })
      .fetch<BooleanResult>();
    return result;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const updateProfile = async ({
  firstname,
  lastname,
  email,
  token,
}: {
  firstname: string;
  lastname: string;
  email: string;
  token: string;
}) => {
  try {
    const result = await authClient
      .new()
      .auth(token)
      .post("/profile")
      .body({ firstname, lastname, email })
      .fetch<BooleanResult>();
    return result;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const updateMaterials = async ({
  materials,
  token,
}: {
  materials: string[];
  token: string;
}) => {
  try {
    const result = await authClient
      .new()
      .auth(token)
      .post("/materials")
      .body({ materials })
      .fetch<BooleanResult>();
    return result;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export type SavePollResult = {
  error: boolean;
};

export const savePoll = async (data: {
  teacherId: string | null;
  studentId: string | null;
  bearer: string | null;
  answers: number[];
  comment: string;
}) => {
  try {
    const result = await publicClient
      .new()
      .post("/polls")
      .body(data)
      .fetch<SavePollResult>();
    return result;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export type CompanionResult = {
  error: boolean;
  data: string;
};

export const ask = async (data: {
  question: string;
  answer: string;
  discussion: string[];
}) => {
  try {
    const result = await publicClient
      .new()
      .post("/companion")
      .body(data)
      .fetch<CompanionResult>();
    return result;
  } catch (e) {
    return {
      error: true,
      data: "",
    };
  }
};

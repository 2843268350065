const Config = {
  EXPRESS: {
    host:
      process.env.REACT_APP_STAGE === "production"
        ? "https://theta-ws.wakirisapi.com/"
        : process.env.REACT_APP_STAGE === "staging"
        ? "https://theta-ws-staging.wakirisapi.com/"
        : "http://localhost:8080/",
  },
  WEBSOCKET: {
    host:
      process.env.REACT_APP_STAGE === "production"
        ? "wss://theta-ws.wakirisapi.com/ws"
        : process.env.REACT_APP_STAGE === "staging"
        ? "wss://theta-ws-staging.wakirisapi.com/ws"
        : "ws://localhost:8080/ws",
  },
  TOKBOX: {
    apiKey: "46269502",
  },
  AWS: {
    bucketHost: "https://d368xsnc6vkndl.cloudfront.net",
  },
};

export default Config;

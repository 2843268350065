import React, { createContext, useContext, useEffect, useState } from "react";
import { useWSSession } from "./provideWSSession";

export interface ChatMessage {
  authorId: string;
  name: string;
  text: string | React.ReactElement;
  at: string;
}

export const ChatContext = createContext<{
  messages: ChatMessage[];
  setMessages: (messages: ChatMessage[]) => void;
  isTyping: number;
  setIsTyping: (value: number) => void;
} | null>(null);

export function ProvideChat({ children }: { children: React.ReactNode }) {
  const provider = useProvideChat();
  return (
    <ChatContext.Provider value={provider}>{children}</ChatContext.Provider>
  );
}

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === null) {
    throw Error("Chat context not provided");
  }
  return context;
};

const useProvideChat = () => {
  const { registerMessageListener, unregisterMessageListener } = useWSSession();

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isTyping, setIsTyping] = useState(0);

  useEffect(() => {
    registerMessageListener({
      id: "is-typing-listener",
      type: "is-typing",
      handler: () => {
        setIsTyping(new Date().getTime() + 2000);
      },
    });

    registerMessageListener({
      id: "chat-listener",
      type: "chat-new",
      handler: (message) => {
        setMessages((messages) => [...messages, message.data]);
      },
    });

    registerMessageListener({
      id: "chat-listener-all",
      type: "chat-all",
      handler: (messages) => {
        setMessages(messages.data);
      },
    });

    registerMessageListener({
      id: "new-joiner-listener",
      type: "new-joiner",
      handler: (message) => {
        setMessages((messages) => [
          ...messages,
          {
            authorId: "bot",
            name: "Evènement",
            at: new Date().toISOString(),
            text: (
              <>
                {[
                  "Un nouvel élève a rejoint la classe.",
                  ...Object.entries(message.data.student.data)
                    .filter(([key]) =>
                      ["name", "firstname", "lastname"].includes(key)
                    )
                    .map(([key, value]) => `${key}: ${value}`),
                  ...Object.entries(message.data.answers)
                    .filter(([key]) =>
                      ["Classe", "Matiere", "Question"].includes(key)
                    )
                    .map(([key, value]) => `${key}: ${value}`),
                ].map((m, id) => (
                  <React.Fragment key={id}>
                    {m}
                    <br />
                  </React.Fragment>
                ))}
              </>
            ),
          },
        ]);
      },
    });

    return () => {
      unregisterMessageListener("is-typing-listener");
      unregisterMessageListener("chat-listener");
      unregisterMessageListener("chat-listener-all");
      unregisterMessageListener("new-joiner-listener");
    };
  }, [registerMessageListener, unregisterMessageListener]);

  return {
    messages,
    setMessages,
    isTyping,
    setIsTyping,
  };
};

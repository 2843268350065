import { Box, Flex, Text } from "@chakra-ui/react";

interface MessageProps {
  mine?: boolean;
  name: string;
  text: string | React.ReactElement;
  at: string;
}

export default function Message({ mine, name, text, at }: MessageProps) {
  const urlify = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part) => {
      if (part.match(urlRegex)) {
        return (
          <a
            href={part}
            key={part}
            target="_blank"
            style={{ textDecoration: "underline" }}
            rel="noreferrer"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <Box
      padding={2}
      borderRadius={6}
      background={
        name === "Evènement" ? "#3E4756" : mine ? "#112737" : "#011727"
      }
      width="80%"
      marginLeft={name === "Evènement" ? "10%" : mine ? "20%" : 0}
    >
      <Flex justifyContent="space-between" gap={2}>
        <Text
          fontSize="xs"
          color={name === "Evènement" ? "gary.300" : "gray.500"}
        >
          {name === "Evènement" ? text : name}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {new Date(at).toLocaleTimeString() === "Invalid Date"
            ? ""
            : new Date(at).toLocaleTimeString()}
        </Text>
      </Flex>
      {name !== "Evènement" && (
        <Text fontSize="xs" color="gray.300">
          {typeof text === "string" ? urlify(text) : text}
        </Text>
      )}
    </Box>
  );
}

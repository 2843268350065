import { Flex, Spinner, Text, useColorModeValue } from "@chakra-ui/react";

export default function Loader() {
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Flex
      background="rgb(32, 33, 35)" //url(/roomBackground.jpg) no-repeat center center"
      backgroundSize="cover"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        borderRadius={6}
        bg={bgColor}
        gap={3}
        p={8}
      >
        <Spinner size="lg" />
        <Text fontSize="md">Connection en cours...</Text>
      </Flex>
    </Flex>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { useWSSession } from "./provideWSSession";

export type DrawingTool =
  | "brush"
  | "line"
  | "rectangle"
  | "circle"
  | "text"
  | "eraser";

export type CursorPosition = { x: number; y: number };

export type DrawCommand = {
  type: DrawingTool | "clear";
  positions: CursorPosition[];
  lineWidth: number;
  fill: string;
  stroke: string;
  extraValue?: string;
};

export const WhiteboardContext = createContext<{
  commands: DrawCommand[];
} | null>(null);

export function ProvideWhiteboard({ children }: { children: React.ReactNode }) {
  const provider = useProvideWhiteboard();
  return (
    <WhiteboardContext.Provider value={provider}>
      {children}
    </WhiteboardContext.Provider>
  );
}

export const useWhiteboard = () => {
  const context = useContext(WhiteboardContext);
  if (context === null) {
    throw Error("Whiteboard context not provided");
  }
  return context;
};

const useProvideWhiteboard = () => {
  const { registerMessageListener, unregisterMessageListener } = useWSSession();

  const [commands, setCommands] = useState<DrawCommand[]>([]);

  useEffect(() => {
    registerMessageListener({
      id: "draw-listener",
      type: "draw",
      handler: (message) => {
        setCommands((c) => [...c, message.data as DrawCommand]);
      },
    });
    registerMessageListener({
      id: "all-draw-listener",
      type: "all-draw",
      handler: (message) => {
        setCommands([...(message.data as DrawCommand[])]);
      },
    });
    return () => {
      unregisterMessageListener("draw-listener");
      unregisterMessageListener("all-draw-listener");
    };
  }, [registerMessageListener, unregisterMessageListener, setCommands]);

  return {
    commands,
  };
};

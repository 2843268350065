import React from "react";
import { Box } from "@chakra-ui/react";

export default function MainPanel({ children }: { children: React.ReactNode }) {
  return (
    <Box
      w={["100%", "100%", "calc(100% - 272px)"]}
      ml={["0", "0", "272px"]}
      p={["24px", "24px", "30px 15px 30px 30px"]}
    >
      {children}
    </Box>
  );
}
